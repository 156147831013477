@import url('https://fonts.googleapis.com/css2?family=Freeman&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;



body{
  margin: 0;
  padding: 0;
  height: 100vh;
}
*{
  box-sizing: border-box;
}

/* This changes all the animations globally */
:root {
  --animate-duration: 500ms;
  --animate-delay: 0.9s;
}

/*************************************** navbar *******************************/
.scroll-header{
  box-shadow: -0 -1px 10px rgba(0, 0, 0, 0.15);
}

/*************************************** scroll to top *******************************/
.show-scroll{
  bottom:8px;
}

/*************************************** jobcard *******************************/
.main-body{
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}


/*************************************** Post Job *******************************/

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.discuss-link{
  text-decoration: none;
  color: black;
}


/*************************************** Loader *******************************/
.dots-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.dot {
  height: 10px;
  width: 10px;
  margin-right: 10px;
  border-radius: 10px;
  background-color: #b3d4fc;
  animation: pulse 1.5s infinite ease-in-out;
}

.dot:last-child {
  margin-right: 0;
}

.dot:nth-child(1) {
  animation-delay: -0.3s;
}

.dot:nth-child(2) {
  animation-delay: -0.1s;
}

.dot:nth-child(3) {
  animation-delay: 0.1s;
}

@keyframes pulse {
  0% {
    transform: scale(0.8);
    background-color: #b3d4fc;
    box-shadow: 0 0 0 0 rgba(178, 212, 252, 0.7);
  }

  50% {
    transform: scale(1.2);
    background-color: #6793fb;
    box-shadow: 0 0 0 10px rgba(178, 212, 252, 0);
  }

  100% {
    transform: scale(0.8);
    background-color: #b3d4fc;
    box-shadow: 0 0 0 0 rgba(178, 212, 252, 0.7);
  }
}